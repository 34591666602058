import React from 'react';

const experienceContent = [
	{
		year: '2020 - Present',
		position: 'Senior Web Developer',
		companyName: 'HomeServe USA',
		details: `Scope, design, and lead technical projects, laying the groundwork for early-stage products to iteratively evolve and scale.`,
	},
	{
		year: '2020 - 2020',
		position: 'Web Developer II',
		companyName: 'Verisk Analytics',
		details: `Served role as Lead Web Developer interacting with stakeholders, performing analysis, design review and creating internal and external web apps.`,
	},
	{
		year: '2018 - 2020',
		position: 'Web Developer I',
		companyName: 'Verisk Analytics',
		details: `Created beautiful web apps and interactive dashboard experiences using technologies such as Angular, TypeScript, .NET and SASS.`,
	},
	{
		year: '2016 - 2018',
		position: 'Acquisitions Analyst',
		companyName: 'Acadia Realty Trust',
		details: `Modeled, underwrote and valued various propery acquisitions on behalf of Fund Investors with over $1.5B of buying power.`,
	},
	{
		year: '2016 - 2016',
		position: 'Relationship Manager',
		companyName: 'Wells Fargo Bank',
		details: `Managed a portoflio of 20 loans across five relationships completing quarterly valuation and asset management reports along with guarantor financial reviews.`,
	},
	{
		year: '2014 - 2016',
		position: 'Financial Analyst',
		companyName: 'Wells Fargo Bank',
		details: `Underwrote and closed over $600M of debt facilities for multifamily, condominium, office and retail properties.`,
	},
	{
		year: '2014 - 2014',
		position: 'Analytics Associate',
		companyName: 'Related Companies',
		details: `Asset management on a portfolio of 25 properties in 3 states, while reducing total leasing costs by $640K.`,
	},
	{
		year: '2013 - 2014',
		position: 'Lease Analyst',
		companyName: 'Jones Lang LaSalle',
		details: `Performed annual audit of operating expenses for leased properties that resulted in cost savings of over $2.3M.`,
	},
];

const Experience = () => {
	return (
		<ul>
			{experienceContent.map((val, i) => (
				<li key={i}>
					<div className='icon'>
						<i className='fa fa-briefcase'></i>
					</div>
					<span className='time open-sans-font text-uppercase'>{val.year}</span>
					<h5 className='poppins-font text-uppercase'>
						{val.position}
						<span className='place open-sans-font'>{val.companyName}</span>
					</h5>
					<p className='open-sans-font'>{val.details}</p>
				</li>
			))}
		</ul>
	);
};

export default Experience;
