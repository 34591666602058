import React from 'react';

const ModalTwoContent = () => {
	return (
		<div className='slideshow'>
			<figure>
				{/* Project Details Starts */}
				<figcaption>
					<h3>Website</h3>
					<div className='row open-sans-font'>
						<div className='col-12 col-sm-6 mb-2'>
							<i className='fa fa-file-text-o pr-2'></i>
							<span className='project-label'>Project </span>:{' '}
							<span className='ft-wt-600 uppercase'>Product Website</span>
						</div>
						{/* End .col */}

						<div className='col-12 col-sm-6 mb-2'>
							<i className='fa fa-user-o pr-2'></i>
							<span className='project-label'>Role </span>:{' '}
							<span className='ft-wt-600 uppercase'>Web Developer</span>
						</div>
						{/* End .col */}

						<div className='col-12 col-sm-6 mb-2'>
							<i className='fa fa-code pr-2'></i>
							<span className='project-label'>Languages </span>:{' '}
							<span className='ft-wt-600 uppercase'>
								React, JavaScript, SASS
							</span>
						</div>
						{/* End .col */}

						<div className='col-12 col-sm-6 mb-2'>
							<i className='fa fa-external-link pr-2'></i>
							<span className='ft-wt-600 uppercase'>
								<a
									href='https://acquire-cx.s3.us-east-2.amazonaws.com/index.html'
									target='_blank'
									rel='noreferrer'
								>
									Preview Site
								</a>
							</span>
						</div>
						{/* End .col */}
					</div>
					{/* End .row */}
				</figcaption>
				{/* Project Details Ends */}

				{/*  Main Project Content Starts */}
				<img src='img/projects/acquire.png' alt='Portolio' />
				{/* Main Project Content Ends */}
			</figure>
		</div>
		//  End .slideshow
	);
};

export default ModalTwoContent;
