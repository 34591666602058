import React from 'react';

const Address = () => {
	return (
		<>
			{/* <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">Address Point</span>123 Stree New York City ,
        United States Of America 750065.
      </p> */}
			{/* End .custom-span-contact */}

			<p className='open-sans-font custom-span-contact position-relative'>
				<i className='fa fa-envelope-open position-absolute'></i>
				<span className='d-block'>mail me</span>{' '}
				<a href='mailto:campusano13@gmail.com'>campusano13@gmail.com</a>
			</p>
			{/* End .custom-span-contact */}

			<p className='open-sans-font custom-span-contact position-relative'>
				<i className='fa fa-phone-square position-absolute'></i>
				<span className='d-block'>call me</span>{' '}
				<a href='tel:2035140871'>(203) 514-0871</a>
			</p>
			{/* End .custom-span-contact */}
		</>
	);
};

export default Address;
