import Modal from 'react-modal';

Modal.setAppElement('#root');

const Blog = () => {
	return (
		<>
			{/* Blog 1 Starts */}
			<div className='col-12 col-md-6 col-lg-6 col-xl-4 mb-30'>
				<a
					href='https://smallbusiness.withgoogle.com/retail/#!/'
					target='_blank'
					className='postLink'
					rel='noopener noreferrer'
				>
					<article className='post-container' id='post1'>
						<div className='post-thumb'>
							<div className='d-block position-relative overflow-hidden'>
								<img
									src='img/blog/googleLogo.jpg'
									className='img-fluid'
									alt='Blog Post'
								/>
							</div>
						</div>
						{/* End .thumb */}
						<div className='post-content'>
							<div className='entry-header'>
								<h3>Put digital to work for your retail business</h3>
							</div>
							<div className='entry-content open-sans-font'>
								<p>
									Retail businesses sell products in physical stores, online, or
									often both. No matter what you offer, customers want to find
									you, buy from you, and keep in touch online.
								</p>
							</div>
						</div>
						{/* End .post-content */}
					</article>
				</a>
			</div>
			{/*  Blog 1 Ends */}

			{/*  Blog 2 Starts */}
			<div className='col-12 col-md-6 col-lg-6 col-xl-4 mb-30'>
				<a
					href='https://smallbusiness.withgoogle.com/service/#!/'
					target='_blank'
					className='postLink'
					rel='noopener noreferrer'
				>
					<article className='post-container' id='post2'>
						<div className='post-thumb'>
							<div className='d-block position-relative overflow-hidden'>
								<img
									src='img/blog/googleLogo.jpg'
									className='img-fluid'
									alt='Blog Post'
								/>
							</div>
						</div>
						{/* End .thumb */}
						<div className='post-content'>
							<div className='entry-header'>
								<h3>Put digital to work for your service business</h3>
							</div>
							<div className='entry-content open-sans-font'>
								<p>
									As a service business, you’re in the business of offering
									customers your help, time, and expertise. They want to be able
									to find, connect, and transact with you online.
								</p>
							</div>
						</div>
						{/* End .post-content */}
					</article>
				</a>
			</div>
			{/*  Blog 2 Ends */}

			{/*  Blog 3 Starts */}
			<div className='col-12 col-md-6 col-lg-6 col-xl-4 mb-30'>
				<a
					href='https://smallbusiness.withgoogle.com/restaurant/#!/'
					target='_blank'
					className='postLink'
					rel='noopener noreferrer'
				>
					<article className='post-container' id='post2'>
						<div className='post-thumb'>
							<div className='d-block position-relative overflow-hidden'>
								<img
									src='img/blog/googleLogo.jpg'
									className='img-fluid'
									alt='Blog Post'
								/>
							</div>
						</div>
						{/* End .thumb */}
						<div className='post-content'>
							<div className='entry-header'>
								<h3>Put digital to work for your restaurant</h3>
							</div>
							<div className='entry-content open-sans-font'>
								<p>
									People are always searching for the next place to eat, drink,
									or socialize. Make it simple for them to discover your
									restaurant, see what you offer, and make a res...
								</p>
							</div>
						</div>
						{/* End .post-content */}
					</article>
				</a>
			</div>
			{/*  Blog 3 Ends */}
		</>
	);
};

export default Blog;
