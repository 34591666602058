import React from 'react';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ModalThreeContent = () => {
	// var settings = {
	// 	dots: true,
	// 	infinite: false,
	// 	speed: 500,
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	draggable: false,
	// };

	return (
		<div className='slideshow'>
			<figure>
				{/* Project Details Starts */}
				<figcaption>
					<h3>E-Commerce Website</h3>
					<div className='row open-sans-font'>
						<div className='col-12 col-sm-6 mb-2'>
							<i className='fa fa-file-text-o pr-2'></i>
							<span className='project-label'>Project </span>:{' '}
							<span className='ft-wt-600 uppercase'>Coder Gear Shop</span>
						</div>
						{/* End .col */}

						<div className='col-12 col-sm-6 mb-2'>
							<i className='fa fa-user-o pr-2'></i>
							<span className='project-label'>Role </span>:{' '}
							<span className='ft-wt-600 uppercase'>Web Developer</span>
						</div>
						{/* End .col */}

						<div className='col-12 col-sm-6 mb-2'>
							<i className='fa fa-code pr-2'></i>
							<span className='project-label'>Languages </span>:{' '}
							<span className='ft-wt-600 uppercase'>Shopify, Liquid</span>
						</div>
						{/* End .col */}

						<div className='col-12 col-sm-6 mb-2'>
							<i className='fa fa-external-link pr-2'></i>
							<span className='ft-wt-600 uppercase'>
								<a
									href='https://codergear.store'
									target='_blank'
									rel='noreferrer'
								>
									Preview Site
								</a>
							</span>
						</div>
						{/* End .col */}
					</div>
					{/* End .row */}
				</figcaption>
				{/* Project Details Ends */}

				{/*  Main Project Content Starts */}
				{/* <Slider {...settings}>
					<div className='slide_item'>
						<img src='img/projects/project-3.jpg' alt='slide 1' />
					</div>
					<div className='slide_item'>
						<img src='img/projects/project-2.jpg' alt='slide 1' />
					</div>
					<div className='slide_item'>
						<img src='img/projects/project-1.jpg' alt='slide 1' />
					</div>
				</Slider> */}
				<img src='img/projects/codergear.png' alt='Portolio' />
				{/* Main Project Content Ends */}
			</figure>
		</div>
		//  End .slideshow
	);
};

export default ModalThreeContent;
