import React from 'react';

const achievementsContent = [
	{
		title: 'Web Development',
		desc: 'Create high performing and secure websites that are optimized to achieve your set business goals. I can handle all steps required to build, maintain and manage your website.',
	},
	{
		title: 'Responsive Design',
		desc: 'Make your site mobile-friendly, improve the way it looks on devices with both large and small screens, and increase the amount of time that visitors spend on your site.',
	},
	{
		title: 'UX/UI',
		desc: 'Making sure your customers and visitors are experiencing a consistent feel across all devices and platforms and that your site logically flows from one step to the next.',
	},
	{
		title: 'eCommerce Solutions',
		desc: 'There are solutions for everyone, depending on your business process and your needs. Having an online storefront is one of the most straightforward ways to conduct commerce.',
	},
	{
		title: 'SEO',
		desc: "Customers who want you, but can't find you, could be costing a lot of money. I'll help you get found in the right places at the right times.",
	},
	{
		title: 'Digital Marketing',
		desc: "Your business is unique, so if you're looking to increase your business' online presence it's essential that your digital marketing strategy is tailored to meet your specific commercial objectives.",
	},
];

const Achievements = () => {
	return (
		<div className='row'>
			{achievementsContent.map((val, i) => (
				<div className='col-12 col-md-6' key={i}>
					<div className='box-stats with-margin'>
						<h4 className='poppins-font position-relative'>{val.title}</h4>
						<p className='open-sans-font m-0 position-relative'>{val.desc}</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default Achievements;
