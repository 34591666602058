import React from 'react';
import { ReactComponent as ReactLogo } from '../../assets/svg/ReactLogo.svg';
import { ReactComponent as VueLogo } from '../../assets/svg/VueLogo.svg';
import { ReactComponent as AngularLogo } from '../../assets/svg/AngularLogo.svg';
import { ReactComponent as JavascriptLogo } from '../../assets/svg/JavascriptLogo.svg';
import { ReactComponent as TypescriptLogo } from '../../assets/svg/TypescriptLogo.svg';
import { ReactComponent as NodeLogo } from '../../assets/svg/NodeLogo.svg';
import { ReactComponent as SassLogo } from '../../assets/svg/SassLogo.svg';
import { ReactComponent as TailwindLogo } from '../../assets/svg/TailwindLogo.svg';
import { ReactComponent as WordpressLogo } from '../../assets/svg/WordpressLogo.svg';
import { ReactComponent as SketchLogo } from '../../assets/svg/SketchLogo.svg';
import { ReactComponent as FigmaLogo } from '../../assets/svg/FigmaLogo.svg';

const skillsContent = [
	{ skillName: 'React', skillLogo: <ReactLogo /> },
	{ skillName: 'Vue', skillLogo: <VueLogo /> },
	{ skillName: 'Angular', skillLogo: <AngularLogo /> },
	{ skillName: 'JavaScript', skillLogo: <JavascriptLogo /> },
	{ skillName: 'TypeScript', skillLogo: <TypescriptLogo /> },
	{ skillName: 'NodeJs', skillLogo: <NodeLogo /> },
	{ skillName: 'Sass', skillLogo: <SassLogo /> },
	{ skillName: 'TailwindCSS', skillLogo: <TailwindLogo /> },
	{ skillName: 'WordPress', skillLogo: <WordpressLogo /> },
	{ skillName: 'Sketch', skillLogo: <SketchLogo width='64' height='64' /> },
	{ skillName: 'Figma', skillLogo: <FigmaLogo /> },
];

const Skills = () => {
	return (
		<>
			{skillsContent.map((val, i) => (
				<div className='col-6 col-md-3 mb-3 mb-sm-5' key={i}>
					<div className='text-center'>{val.skillLogo}</div>
					<h6 className='open-sans-font text-center mt-2 mt-sm-4'>
						{val.skillName}
					</h6>
				</div>
			))}
		</>
	);
};

export default Skills;
