import React from 'react';
import Certifications from './Certifications';
import Education from './Education';
import Experience from './Experience';
import Services from './Services';
import Skills from './Skills';

const index = () => {
	return (
		<section className='main-content '>
			<div className='container'>
				<div className='row'>
					<div className='col-12 mb-5'>
						<p className='about-me-intro'>
							Do you want to launch a new website or are you looking to enhance
							your online presence? I can offer you a range of robust, custom
							solutions designed to convert visitors into customers and to
							produce tangible business results.
						</p>
					</div>
				</div>

				<div className='row'>
					<div className='col-12'>
						<h3 className='text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-left text-sm-center custom-title ft-wt-600'>
							Services
						</h3>
					</div>
					<Services />
				</div>
				{/* End .row */}

				<hr className='separator' />

				{/* Skills Starts */}
				<div className='row'>
					<div className='col-12'>
						<h3 className='text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-left text-sm-center custom-title ft-wt-600'>
							My Skills
						</h3>
					</div>
					<Skills />
				</div>
				{/* Skills Ends */}

				<hr className='separator mt-1' />

				{/* Experience & Education Starts */}
				<div className='row'>
					<div className='col-12'>
						<h3 className='text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600'>
							Experience <span>&</span> Education
						</h3>
					</div>
					<div className='col-lg-6 m-15px-tb'>
						<div className='resume-box'>
							<Experience />
						</div>
					</div>
					<div className='col-lg-6 m-15px-tb'>
						<div className='resume-box'>
							<Education />
						</div>
					</div>
				</div>
				{/*  Experience & Education Ends */}

				<hr className='separator mt-1' />

				{/* Certification Starts */}
				<div className='row'>
					<div className='col-12'>
						<h3 className='text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600'>
							Certifications
						</h3>
					</div>
					<div className='col-lg-6 m-15px-tb'>
						<div className='resume-box'>
							<Certifications />
						</div>
					</div>
				</div>
				{/* Certification Ends */}
			</div>
		</section>
	);
};

export default index;
