import React from 'react';

const experienceContent = [
	{
		year: '2022 - 2023',
		position: 'Fundamentals of Digital Marketing',
		companyName: 'Google',
		details: `Course certificate that covers Digital Marketing channels, strategies and tools such as Search Engines, Search Marketing, Paid Advertising, Social Media, Mobile Marketing and Analytics. Credential ID: QUV PTA DGL`,
	},
];

const Certifications = () => {
	return (
		<ul>
			{experienceContent.map((val, i) => (
				<li key={i}>
					<div className='icon'>
						<i className='fa fa-id-card-o'></i>
					</div>
					<span className='time open-sans-font text-uppercase'>{val.year}</span>
					<h5 className='poppins-font text-uppercase'>
						{val.position}
						<span className='place open-sans-font'>{val.companyName}</span>
					</h5>
					<p className='open-sans-font'>{val.details}</p>
				</li>
			))}
		</ul>
	);
};

export default Certifications;
